import React, { Component, Fragment } from "react";

import { Row, Col } from "react-grid-system";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import mapValues from "lodash.mapvalues";
import styled from "styled-components";
import moment from "moment";

import getAxiosErrorMessage from "../../utils/getAxiosErrorMessage";
import type { AnyQuestionValue, Message } from "../../utils/types";
import session from "../../session";
import CheckboxesText from "../fieldsets/CheckboxesText";
import RadioButtons from "../fieldsets/RadioButtons";
import Slider from "../fieldsets/Slider";
import SliderGroup from "../fieldsets/SliderGroup";
import SmallText from "../fieldsets/SmallText";
import Textarea from "../fieldsets/Textarea";
import Layout, { LayoutHeader, LayoutContent } from "../layouts/Layout";
import HeaderSection from "../layouts/HeaderSection";
import { GreenButtonAction } from "../subcomponents/ButtonAction";
import { Loading } from "../subcomponents/Loading";
import SafetyLinkButton from "../subcomponents/SafetyLinkButton";
import { ApiHocProps, withApi } from "../../api";

type ScenarioDef = {
  id: string;
  title: string;
  description: string;
  slug: string;
  image: string;
  pdfOrder: Array<string>;
  questions: Array<any>;
  content: any;
};

type Props = {
  t: TFunction;
  history: {
    push: (newRoute: string) => void;
    location: {
      state: {
        scenarioDef: ScenarioDef;
        message?: Message;
      };
    };
  };
};

type State = {
  awaitingResponse: boolean;
  // Need to better define type once we decide - imagining it will be something that contains Obj of type AnyQuestionValue
  formData: Record<string, AnyQuestionValue>;
  // We can change this to hold more bespoke values in future.
  loadedOptions?: { meetings: Array<{ name: string }> };
  message?: Message;
};

// this is copy pasted from routes.jsx TODO: centralize route strings?
const toOnwardPath = (id: unknown) => {
  const encodedId = encodeURIComponent(`${id}`);
  return `/preview/${encodedId}`;
};

class Scenario extends Component<ApiHocProps<Props>, State> {
  constructor(props: ApiHocProps<Props>) {
    super(props);

    this.state = {
      formData: {},
      awaitingResponse: true,
    };

    this.buildScenario = this.buildScenario.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  getLocationState() {
    return this.props.history.location.state || {};
  }

  async componentDidMount() {
    // We only want to fetch if this is the scenario with meetingTypes.
    const { scenarioDef } = this.getLocationState();
    if (scenarioDef && scenarioDef.id === "preparation") {
      const chosenService = localStorage.getItem("chosen-service");
      const meetingsUrl = chosenService
        ? `/user/services/${chosenService}/meetings`
        : `/user/services/meetings`;
      let userServicesMeetings = [];
      try {
        userServicesMeetings = await this.props.Api.get(meetingsUrl).then(
          function (response) {
            return response.data;
          },
        );
      } catch (e) {
        return this.setState({
          awaitingResponse: false,
          message: { text: getAxiosErrorMessage(e), type: "error" },
        });
      }
      const meetings = userServicesMeetings.map(function (meeting) {
        return { name: meeting };
      });
      this.setState({
        awaitingResponse: false,
        loadedOptions: { meetings: meetings },
      });
      // Not trying to load any resources
    } else {
      this.setState({ awaitingResponse: false });
    }
  }

  async onSubmit() {
    const { scenarioDef } = this.getLocationState();

    await this.setState({ awaitingResponse: true });

    const content = mapValues(
      this.state.formData,
      function (questionValue: Readonly<AnyQuestionValue>, key) {
        const { contentKey, value } = questionValue;
        const question = scenarioDef.questions.find((q) => key === q.id);

        return {
          [contentKey]: value,
          label: question.pdfTitle,
        };
      },
    );

    const postData = {
      content,
      label: scenarioDef.title,
      // the id seems to be in the same form as the type from the old app
      // TODO: shall we add it to the scenario data?
      type: scenarioDef.id,
    };

    // TODO try/catch this
    const { data: responseData } = await this.props.Api.post(
      "/statement",
      postData,
    );

    const nextURL = toOnwardPath(responseData.id);

    this.props.history.push(nextURL);
  }

  onChange(fieldValue: Readonly<AnyQuestionValue>) {
    const { id } = fieldValue;

    this.setState(function (prevState) {
      return {
        formData: {
          ...prevState.formData,
          [id]: fieldValue,
        },
      };
    });
  }

  buildScenario() {
    const { scenarioDef } = this.getLocationState();

    const userDOB = new Date(session?.user?.dob || Date.now());
    const userAgeinYears = moment(Date.now()).diff(moment(userDOB), "years");

    // Iterate over the scenarioDefinitions questions
    const scenarioQuestions = scenarioDef.questions.map((question) => {
      const generalProps = {
        id: question.id,
        heading: question.title,
        label: question.description,
        key: question.id,
        onChangeNotify: this.onChange,
      };

      // Replace default meetings with a user's service's specific meeting types
      if (question.id === "meetingtype" && this.state.loadedOptions) {
        question.radioOptions = this.state.loadedOptions.meetings;
      }

      // Remove question if a user is not old enough to see it
      if (question?.ageGuard > userAgeinYears) {
        return null;
      }

      // Add specific props for each question and return question component
      switch (question.type) {
        case "checkboxes":
        case "checkboxestext":
          return (
            <CheckboxesText
              {...generalProps}
              initCheckboxes={question.checkboxes}
              type={question.type}
              iconSize={question.iconSize || "default"}
            />
          );
        case "textarea":
          return (
            <Textarea
              {...generalProps}
              placeholder={question.placeholder}
              tags={question.tags}
              value={question.text}
            />
          );
        case "slider":
          return (
            <Slider
              {...generalProps}
              hint={question.hint}
              positions={question.positions}
            />
          );
        case "sliderGroup":
          return (
            <SliderGroup
              {...generalProps}
              subQuestions={question.subQuestions}
            />
          );
        case "smalltext":
          return (
            <SmallText {...generalProps} placeholder={question.placeholder} />
          );
        case "radiofixed":
        case "radioplus":
          return (
            <RadioButtons
              {...generalProps}
              initRadioOptions={question.radioOptions}
              type={question.type}
            />
          );
        default:
          console.error("Invalid question type provided to scenario builder");
          return null;
      }
    });
    return scenarioQuestions;
  }

  // TODO maybe move this to utils - for mixed data object contains something with isValid true.
  hasValid(formData) {
    return Object.keys(formData).some(function (id) {
      return formData[id] && formData[id].isValid;
    });
  }

  render() {
    const { t } = this.props;
    const { formData } = this.state;
    const { scenarioDef, message } = this.getLocationState();
    const childlinePhone = t("pages:scenarios.link_helpline.number");
    if (scenarioDef === undefined) {
      this.props.history.push("/scenarios");
      return;
    }

    return (
      <Layout>
        <LayoutHeader>
          <HeaderSection
            heading={scenarioDef.title}
            subheading={scenarioDef.description}
            message={message}
            icon={scenarioDef.image}
          />
        </LayoutHeader>

        <LayoutContent>
          {(scenarioDef.id === "youth-justice-and-me" ||
            scenarioDef.id === "my-world") &&
          childlinePhone ? (
            <SafetyLinkButton />
          ) : null}
          {!this.state.awaitingResponse ? (
            <Fragment>
              {/* Build scenario questions */}
              {this.buildScenario()}
              {/* Scenario send section */}
              <Row justify="center">
                <Col xs={12} md={6} lg={4} style={{ paddingBottom: "0.5rem" }}>
                  <Guidance>{t("pages:scenario.save_guidance")}</Guidance>
                  <GreenButtonAction
                    name={t("pages:scenario.save_button")}
                    onClick={this.onSubmit}
                    disabled={
                      !this.state.awaitingResponse && !this.hasValid(formData)
                    }
                  />
                </Col>
              </Row>
            </Fragment>
          ) : (
            <Loading />
          )}
        </LayoutContent>
      </Layout>
    );
  }
}

const Guidance = styled.p`
  margin-bottom: 0;
  text-align: center;
`;

export default withTranslation()(withApi(Scenario));
